import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { Global } from '@emotion/core';
import { ErrorBoundary } from 'react-error-boundary';

import Loader from '@reactsim/common/dist/Components/General/LoadingIcon/LoadingIcon';
import IntlProviderWrapper from '@reactsim/common/dist/Components/Simulation/IntlProviderWrapper/IntlProviderWrapper';
import globalStyles from '@reactsim/common/dist/Shared/StyleHelpers/Globals';

import store from './Store';
import translations from './Translations/locales';
import messages from './Simulation.messages';

const AsyncLoginCheck = lazy(() => import('@reactsim/common/dist/Components/Simulation/LoginCheck/LoginCheck'));
const AsyncWrapper = lazy(() => import('@reactsim/common/dist/WrapperComponents/Wrapper'));
const AsyncSimulation = lazy(() => import('./Components/Simulation/Simulation'));

const App = () => (
    <Provider store={store}>
        <Global styles={globalStyles} />
        <BrowserRouter>
            <Suspense fallback={<Loader />}>
                <Switch>
                    <Route
                        exact
                        strict
                        path="/"
                        render={(params) => (
                            <IntlProviderWrapper {...params} processEnv={process.env} ignoreLang page="Main">
                                {AsyncWrapper}
                            </IntlProviderWrapper>
                        )}
                    />
                    <Route
                        path="/:program"
                        exact
                        render={(params) => <AsyncLoginCheck {...params} processEnv={process.env} />}
                    />
                    <Route
                        path="/:program/simulation"
                        render={(params) => {
                            return (
                                <ErrorBoundary
                                    fallbackRender={() => {
                                        return (
                                            <IntlProviderWrapper {...params} processEnv={process.env} page="Maintenance">
                                                {AsyncWrapper}
                                            </IntlProviderWrapper>
                                    )}}
                                >
                                    <IntlProviderWrapper
                                        {...params}
                                        processEnv={process.env}
                                        context="light"
                                        translations={translations}
                                    >
                                        {AsyncSimulation}
                                    </IntlProviderWrapper>
                                </ErrorBoundary>
                        )
                        }}
                    />
                    <Route
                        path="/:program/login/direct"
                        render={(params) => (
                            <IntlProviderWrapper
                                {...params}
                                processEnv={process.env}
                                translations={translations}
                                messages={messages}
                                context="light"
                                page="DirectLogin"
                            >
                                {AsyncWrapper}
                            </IntlProviderWrapper>
                        )}
                    />
                    <Route
                        path="/:program/login"
                        render={(params) => (
                            <IntlProviderWrapper {...params} processEnv={process.env} page="oAuthLogin">
                                {AsyncWrapper}
                            </IntlProviderWrapper>
                        )}
                    />
                    <Route
                        path="/:program/oauth-error"
                        render={(params) => (
                            <IntlProviderWrapper {...params} processEnv={process.env} page="Error">
                                {AsyncWrapper}
                            </IntlProviderWrapper>
                        )}
                    />
                    <Route
                        path="/:program/not-found"
                        render={(params) => (
                            <IntlProviderWrapper {...params} processEnv={process.env} page="NotFound">
                                {AsyncWrapper}
                            </IntlProviderWrapper>
                        )}
                    />
                    <Route
                        path="/:program/maintenance"
                        render={(params) => (
                            <IntlProviderWrapper {...params} processEnv={process.env} page="Maintenance">
                                {AsyncWrapper}
                            </IntlProviderWrapper>
                        )}
                    />
                    <Route
                        path="/:program/*"
                        render={(params) => (
                            <IntlProviderWrapper {...params} processEnv={process.env} page="NotFound">
                                {AsyncWrapper}
                            </IntlProviderWrapper>
                        )}
                    />
                </Switch>
            </Suspense>
        </BrowserRouter>
    </Provider>
);

export default App;
