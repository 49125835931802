"use strict";var _interopRequireDefault=require("@babel/runtime/helpers/interopRequireDefault");Object.defineProperty(exports,"__esModule",{value:true});exports["default"]=orders;var _toConsumableArray2=_interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));var _defineProperty2=_interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));var _Reducer=require("../Dialog/Reducer");var _Actions=require("./Actions");function ownKeys(e,r){var t=Object.keys(e);if(Object.getOwnPropertySymbols){var o=Object.getOwnPropertySymbols(e);r&&(o=o.filter(function(r){return Object.getOwnPropertyDescriptor(e,r).enumerable;})),t.push.apply(t,o);}return t;}function _objectSpread(e){for(var r=1;r<arguments.length;r++){var t=null!=arguments[r]?arguments[r]:{};r%2?ownKeys(Object(t),!0).forEach(function(r){(0,_defineProperty2["default"])(e,r,t[r]);}):Object.getOwnPropertyDescriptors?Object.defineProperties(e,Object.getOwnPropertyDescriptors(t)):ownKeys(Object(t)).forEach(function(r){Object.defineProperty(e,r,Object.getOwnPropertyDescriptor(t,r));});}return e;}var initialState=_objectSpread(_objectSpread({},_Reducer.ordersDialogs),{},{patientDrugs:[],frequencies:[],nonMedications:[],currentDrugs:[],continuedDrugs:[],discontinuedDrugs:[],patientDrugGroups:[]});function orders(){var state=arguments.length>0&&arguments[0]!==undefined?arguments[0]:initialState;var action=arguments.length>1?arguments[1]:undefined;switch(action.type){case _Actions.ORDERS_LOAD_NON_MEDICATIONS:{var _action$payload=action.payload,patientOrders=_action$payload.orders,storedOrders=_action$payload.storedOrders,storedConsults=_action$payload.storedConsults;// Add a type of operation to perform on each order
var nonMedications=patientOrders.map(function(item){return{id:item.iItemId,name:item.sName,category:item.sCategory,position:item.iPosition,type:'add'};});if(storedOrders){nonMedications=nonMedications.map(function(item){var match=storedOrders.find(function(order){return order.id===item.id&&order.category===item.category;});if(match){return _objectSpread(_objectSpread({},item),{},{category:item.category,content:match?match.content:null,severity:match?match.severity:null,type:match?'remove':'add'});}return item;});}if(storedConsults){nonMedications=nonMedications.map(function(item){var match=storedConsults.find(function(order){return order.id===item.id&&order.category===item.category;});if(match){return _objectSpread(_objectSpread({},item),{},{category:item.category,content:match?match.content:null,severity:match?match.severity:null,type:match?'remove':'add'});}return item;});}nonMedications.sort(function(a,b){return parseInt(a.position,10)<parseInt(b.position,10)?-1:1;});return _objectSpread(_objectSpread({},state),{},{nonMedications:nonMedications});}case _Actions.ORDERS_LOAD_DRUG_GROUPS:return _objectSpread(_objectSpread({},state),{},{patientDrugGroups:action.payload.map(function(item){return{id:item.iGroupId,name:item.sName,min:parseInt(item.iMinDrugs,10),max:parseInt(item.iMaxDrugs,10),drugCount:parseInt(item.iDrugCount,10),drugs:item.aDrugs,severity:item.aGuidance.length>0?item.aGuidance[0].sSeverity:''};})});case _Actions.ORDERS_DISPLAY_EXISTING_THERAPIES:return _objectSpread(_objectSpread({},state),{},{therapies:_objectSpread(_objectSpread({},state.therapies),{},{status:true})});case _Actions.ORDERS_LOAD_EXISTING_THERAPIES:{// Filter the list against the continued ones (if any)
var therapies=action.payload.filter(function(item){return!state.continuedDrugs.length||state.continuedDrugs.find(function(drug){return drug.iDrugId!==item.iDrugId;});}).map(function(item,index){return _objectSpread(_objectSpread({},item),{},{current:index===0});});return _objectSpread(_objectSpread({},state),{},{therapies:_objectSpread(_objectSpread({},state.therapies),{},{list:therapies})});}case _Actions.ORDERS_LOAD_FREQUENCIES:{var frequencies=[];Object.keys(action.payload).forEach(function(item){frequencies.push({value:action.payload[item].iFrequencyId,label:action.payload[item].sFrequency});});return _objectSpread(_objectSpread({},state),{},{frequencies:frequencies});}case _Actions.ORDERS_LOAD_DRUGS:return _objectSpread(_objectSpread({},state),{},{patientDrugs:action.payload});case _Actions.ORDERS_LOAD_CURRENT_DRUGS:return _objectSpread(_objectSpread({},state),{},{currentDrugs:action.payload});case _Actions.ORDERS_DISPLAY_GUIDELINES:return _objectSpread(_objectSpread({},state),{},{guidelines:{status:true,title:action.payload.title,content:action.payload.content}});case _Actions.ORDERS_UPDATE_THERAPY_STATUS:{var _action$payload2=action.payload,therapy=_action$payload2.therapy,status=_action$payload2.status;var idx=state.therapies.list.findIndex(function(x){return therapy.drugId===x.iDrugId;});var nextIdx=idx+1;// Determine which status the current drug has; once it's determined, check if the drug must be added to
// the current drugs list or not
var container=status==='continue'?'continuedDrugs':'discontinuedDrugs';var statusLabel=status==='continue'?'Continued':'Discontinued';var currentDrugs=status==='continue'?[].concat((0,_toConsumableArray2["default"])(state.currentDrugs),[therapy]):(0,_toConsumableArray2["default"])(state.currentDrugs);var currentStatus={current:false,action:statusLabel};// Once all the therapies have been addressed, change the status of their container to display
// a button to exit the dialog
if(nextIdx<state.therapies.list.length){var nextStatus={current:true};return _objectSpread(_objectSpread({},state),{},(0,_defineProperty2["default"])((0,_defineProperty2["default"])({therapies:_objectSpread(_objectSpread({},state.therapies),{},{list:[].concat((0,_toConsumableArray2["default"])(state.therapies.list.slice(0,idx)),[_objectSpread(_objectSpread({},state.therapies.list[idx]),currentStatus),_objectSpread(_objectSpread({},state.therapies.list[nextIdx]),nextStatus)],(0,_toConsumableArray2["default"])(state.therapies.list.slice(nextIdx+1)))})},container,[].concat((0,_toConsumableArray2["default"])(state[container]),[therapy])),"currentDrugs",currentDrugs));}return _objectSpread(_objectSpread({},state),{},(0,_defineProperty2["default"])((0,_defineProperty2["default"])({therapies:_objectSpread(_objectSpread({},state.therapies),{},{allReconciled:true,list:[].concat((0,_toConsumableArray2["default"])(state.therapies.list.slice(0,idx)),[_objectSpread(_objectSpread({},state.therapies.list[idx]),currentStatus)])})},container,[].concat((0,_toConsumableArray2["default"])(state[container]),[therapy])),"currentDrugs",currentDrugs));}case _Actions.ORDERS_DISPLAY_PRESCRIPTION_PAD:{// Look for any updated values from the current drugs stored
var prescribedValues=state.currentDrugs.find(function(item){return item.drugId===action.payload.drug.iDrugId;})||{};return _objectSpread(_objectSpread({},state),{},{prescriptionPad:{status:true,action:action.payload.action,drug:action.payload.drug,prescribedValues:prescribedValues}});}case _Actions.ORDERS_SET_PRESCRIPTION_PAD_VALUES:return _objectSpread(_objectSpread({},state),{},{prescriptionPad:_objectSpread(_objectSpread({},state.prescriptionPad),{},{prescribedValues:action.payload})});case _Actions.ORDERS_RESET_PRESCRIPTION_PAD:return _objectSpread(_objectSpread({},state),{},{prescriptionPad:_objectSpread(_objectSpread({},state.prescriptionPad),{},{action:action.payload?'add':'modify',drug:action.payload?{}:state.prescriptionPad.drug,// status: action.payload? false : state.prescriptionPad.status,
prescribedValues:action.payload?{}:state.prescriptionPad.prescribedValues})});case _Actions.ORDERS_PRESCRIBE_DRUG:{// Look if the drug has been previously prescribed; if so, update the values and save. Otherwise,
// just add it to the list of current drugs
var index=state.currentDrugs.findIndex(function(x){return action.payload.drugId===x.drugId;});if(index>-1){return _objectSpread(_objectSpread({},state),{},{currentDrugs:[].concat((0,_toConsumableArray2["default"])(state.currentDrugs.slice(0,index)),[action.payload],(0,_toConsumableArray2["default"])(state.currentDrugs.slice(index+1)))});}return _objectSpread(_objectSpread({},state),{},{currentDrugs:[].concat((0,_toConsumableArray2["default"])(state.currentDrugs),[action.payload])});}case _Actions.ORDERS_REMOVE_DRUG:{var drugIdx=state.currentDrugs.findIndex(function(x){return action.payload===x.drugId;});return _objectSpread(_objectSpread({},state),{},{currentDrugs:[].concat((0,_toConsumableArray2["default"])(state.currentDrugs.slice(0,drugIdx)),(0,_toConsumableArray2["default"])(state.currentDrugs.slice(drugIdx+1)))});}case _Actions.ORDERS_UPDATE_DRUG:{var i=state.currentDrugs.findIndex(function(x){return action.payload.id===x.drugId;});if(i>-1){return _objectSpread(_objectSpread({},state),{},{currentDrugs:[].concat((0,_toConsumableArray2["default"])(state.currentDrugs.slice(0,i)),[action.payload],(0,_toConsumableArray2["default"])(state.currentDrugs.slice(i+1)))});}return state;}case _Actions.ORDERS_UPDATE_NON_MEDICATION:{var _action$payload$order=action.payload.order,orderId=_action$payload$order.id,orderCategory=_action$payload$order.category,orderType=_action$payload$order.type;var orderIdx=state.nonMedications.findIndex(function(x){return orderId===x.id&&x.category===orderCategory;});// prettier-ignore
var currentOrder=action.payload.ignoreType?action.payload.order:_objectSpread(_objectSpread({},action.payload.order),{},{type:orderType==='add'?'remove':'add'});if(orderIdx>=0){return _objectSpread(_objectSpread({},state),{},{nonMedications:[].concat((0,_toConsumableArray2["default"])(state.nonMedications.slice(0,orderIdx)),[_objectSpread(_objectSpread({},state.nonMedications[orderIdx]),currentOrder)],(0,_toConsumableArray2["default"])(state.nonMedications.slice(orderIdx+1)))});}return _objectSpread(_objectSpread({},state),{},{nonMedications:state.nonMedications});}case _Actions.ORDERS_CLOSE_DIALOG:{var type=action.payload.name;switch(type){case'guidelines':action.payload.title='';action.payload.content='';break;case'prescriptionPad':state.prescriptionPad.prescribedValues={};state.prescriptionPad.drug={};state.prescriptionPad.action='add';break;case'therapies':state.therapies.list=[];break;default:break;}return _objectSpread(_objectSpread({},state),{},(0,_defineProperty2["default"])({},type,_objectSpread(_objectSpread({},state[type]),{},{status:false,title:action.payload.title?action.payload.title:state[type].title,content:action.payload.content?action.payload.content:state[type].content})));}case _Actions.ORDERS_CLEAR:return _objectSpread({},initialState);default:return state;}}