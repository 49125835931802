import en from './en.json';
import es from './es.json';
import de from './de.json';
import fr from './fr.json';
import it from './it.json';

export default {
    en,
    es,
    fr,
    de,
    it,
};
