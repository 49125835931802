"use strict";var _typeof=require("@babel/runtime/helpers/typeof");Object.defineProperty(exports,"__esModule",{value:true});exports.headingStyles=exports.fontWeights=exports.fontSizes=exports.fontFamilies=exports["default"]=exports.customTypography=exports.anchorsStyles=void 0;var _Colors=_interopRequireWildcard(require("./Colors"));function _getRequireWildcardCache(e){if("function"!=typeof WeakMap)return null;var r=new WeakMap(),t=new WeakMap();return(_getRequireWildcardCache=function _getRequireWildcardCache(e){return e?t:r;})(e);}function _interopRequireWildcard(e,r){if(!r&&e&&e.__esModule)return e;if(null===e||"object"!=_typeof(e)&&"function"!=typeof e)return{"default":e};var t=_getRequireWildcardCache(r);if(t&&t.has(e))return t.get(e);var n={__proto__:null},a=Object.defineProperty&&Object.getOwnPropertyDescriptor;for(var u in e)if("default"!==u&&{}.hasOwnProperty.call(e,u)){var i=a?Object.getOwnPropertyDescriptor(e,u):null;i&&(i.get||i.set)?Object.defineProperty(n,u,i):n[u]=e[u];}return n["default"]=e,t&&t.set(e,n),n;}/* REM Font sizing - http://blog.divshot.com/post/29552945105/useful-sass-mixins-for-responsive-design-font-sizing */var fontSizes=exports.fontSizes=function fontSizes(fontSize,lineSize){var lineHeight=lineSize||fontSize*1.5;return"\n        font-size: ".concat(fontSize*16,"px;\n        line-height: ").concat(lineHeight*16,"px;\n        font-size: ").concat(fontSize,"rem;\n        line-height: ").concat(lineHeight,"rem;\n    ");};/**
 * Stores global simulation font families as defined by the design team
 * @constant
 * @type object fonts
 */var fontFamilies=exports.fontFamilies={"default":'"proxima-nova", Arial, sans-serif',brandon:'"brandon-grotesque", Helvetica, sans-serif'};/**
 * Stores global simulation font weights as defined by the design team
 * @constant
 * @type object fonts
 */var fontWeights=exports.fontWeights={thin:'300',regular:'400',bold:'600',superBold:'700'};/**
 * Stores global simulation standardized font styles as defined by the design team
 * @constant
 * @type object fonts
 */var customTypography=exports.customTypography={body:"\n        color: ".concat(_Colors["default"].fontColors.lighterGray,";\n        font-family: ").concat(fontFamilies["default"],";\n        ").concat(fontSizes(1,1.25),"\n    "),headers:"\n        margin: 0 0 1em;\n        font-family: ".concat(fontFamilies["default"],";\n        font-weight: ").concat(fontWeights.regular,";\n        letter-spacing: .05em;\n        text-transform: uppercase;\n    ")};var anchorsStyles=exports.anchorsStyles="\na {\n    ".concat(customTypography.body,";\n    color: ").concat(_Colors["default"].fontColors.blue,";\n    ").concat(fontSizes(0.9375,1.5),"\n    text-decoration: none;\n    transition: color 0.15s ease;\n\n    &:hover {\n        color: ").concat(_Colors.solidColors.blue4,";\n    }\n}");var headingStyles=exports.headingStyles="\nh1, h2, h3, h4, h5, h6 {\n    ".concat(customTypography.headers,";\n}\nh1 {\n    ").concat(fontSizes(1.6,2),"\n    color: ").concat(_Colors.solidColors.white,";\n}\nh2 {\n    ").concat(fontSizes(1.3,1.6),"\n}\nh3 {\n    ").concat(fontSizes(1.1,1.3),"\n}\nh4 {\n    ").concat(fontSizes(0.9,1),"\n}");var typography="\n    ".concat(headingStyles,"\n    p {\n        ").concat(fontSizes(0.9375,1.5),"\n        margin: 0 0 1em;\n    }\n    ").concat(anchorsStyles,"\n");var _default=exports["default"]=typography;