import { defineMessages } from 'react-intl';

const messages = defineMessages({
    beginNext: {
        id: 'PostSimulation.BeginNext',
        defaultMessage: 'Begin next case',
    },
    share: { id: 'Header.Share', defaultMessage: 'Share' },
    productTitle: {
        id: 'Header.CaseSimulations',
        defaultMessage: 'Medscape Patient Case Simulations',
    },
    socialMessage: {
        id: 'Social.Message',
        defaultMessage: 'I have just completed this patient simulation case from MedSims',
    },
    disclaimer: {
        id: 'Footer.MedscapeDisclaimer',
        defaultMessage: 'Content created and controlled by Medscape',
    },
    offline: {
        id: 'Offline.Message',
        defaultMessage: "It looks like you're offline. We'll keep trying to reconnect.",
    },
    decisionReview: {
        id: 'DecisionReview.Title',
        defaultMessage: 'Decision Review',
    },
    privacyPolicy: {
        id: 'Footer.PrivacyPolicy',
        defaultMessage: 'Privacy Policy',
    },
    needHelp: { id: 'SystemMenu.Help', defaultMessage: 'Need Help?' },
    doNotSell: {
        id: 'SystemMenu.DoNotSell',
        defaultMessage: 'Do Not Sell or Share My Personal Information',
    },
    restart: { id: 'Button.Restart', defaultMessage: 'Restart Progress' },
    return: { id: 'Button.Return', defaultMessage: 'Return to Medscape' },
    nextQuestion: {
        id: 'Interview.NextQuestion',
        defaultMessage: 'Next Question',
    },
    endInterview: {
        id: 'Interview.EndInterview',
        defaultMessage: 'End Interview',
    },
    videoInterview: {
        id: 'Interview.VideoInterview',
        defaultMessage: 'Video Interview',
    },
    caseReview: { id: 'CaseReview.Title', defaultMessage: 'Case Review' },
    chartButton: { id: 'Chart.Button', defaultMessage: 'Chart' },
    testInstructions: {
        id: 'Instructions.Tests',
        defaultMessage: 'Select appropriate tests for the patient.',
    },
    diagnosisInstructions: {
        id: 'Instructions.Diagnosis',
        defaultMessage: 'Select the best new diagnosis.',
    },
    medicationOrderInstructions: {
        id: 'Instructions.MedicationOrders',
        defaultMessage: 'Prescribe appropriate medication(s) for the patient.',
    },
    nonMedicationOrderInstructions: {
        id: 'Instructions.NonMedicationOrders',
        defaultMessage: 'Select appropriate additional orders for the patient.',
    },
    decisionReviewInstructions: {
        id: 'Instructions.CaseReviewInstructions',
        defaultMessage: 'Click icons to review guidance and adjust selections as needed.',
    },
});

export default messages;
