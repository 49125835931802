import { applyMiddleware, createStore } from 'redux';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';

import rootReducer from '@reactsim/common/dist/Shared/Actions/Reducers';

const middleware = [thunk];

if (process.env.REACT_APP_REDUX_LOGGER !== 'off') {
    middleware.push(createLogger());
}

const store = createStore(rootReducer, applyMiddleware(...middleware));

export default store;
