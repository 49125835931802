"use strict";var _interopRequireDefault=require("@babel/runtime/helpers/interopRequireDefault");Object.defineProperty(exports,"__esModule",{value:true});exports.convertToImperial=convertToImperial;exports.customScrollTo=customScrollTo;exports.disableFocusAfterModal=disableFocusAfterModal;exports.displayOneTrustFooterLink=displayOneTrustFooterLink;exports.enableFocusAfterModal=enableFocusAfterModal;exports.fixForiOS=fixForiOS;exports.getTimestamp=getTimestamp;exports.getUserId=getUserId;exports.getValidTimeStamp=getValidTimeStamp;exports.inRange=inRange;exports.isEmpty=isEmpty;exports.isGuidanceVisible=isGuidanceVisible;exports.lowerFirst=lowerFirst;exports.range=range;exports.round=round;exports.startCase=startCase;exports.text2HTML=text2HTML;exports.throttle=throttle;exports.upperFirst=upperFirst;var _mobileDetect=_interopRequireDefault(require("mobile-detect"));var _Storage=_interopRequireDefault(require("./Storage"));/**
 * Convert plain text into HTML output.
 *
 * Borrowed from https://github.com/gbourne1/text2HTML/blob/master/text2HTML.js
 *
 * @param {string} text  The plain text
 * @returns {string}
 */function text2HTML(text){var htmlOutput=text.replace(/&/g,'&amp;').replace(/</g,'&lt;').replace(/>/g,'&gt;').replace(/\r\n?|\n/g,'<br>').replace(/<br>\s*<br>/g,'</p><p>');return"<p>".concat(htmlOutput,"</p>");}/**
 * Convert timestamp (positive or negative) with current timestamp into number.
 *
 * @param {number|string} timestamp
 * @returns {number}
 */function getValidTimeStamp(timestamp){var validTimestamp=parseInt(timestamp,10)*1000;var time=validTimestamp<0?new Date().getTime()+validTimestamp:validTimestamp;return time;}/**
 * Check if an object is empty.
 *
 * @param {Object} object
 * @returns {boolean}
 */function isEmpty(object){return[Object,Array].includes((object||{}).constructor)&&!Object.entries(object||{}).length;}/**
 * Round number
 *
 * @param {number} num
 * @param {number} [precision=0]
 * @returns {number}
 */function round(num){var precision=arguments.length>1&&arguments[1]!==undefined?arguments[1]:0;var modifier=Math.pow(10,precision);return!modifier?Math.round(num):Math.round(num*modifier)/modifier;}function upperFirst(text){return text?"".concat(text.charAt(0).toUpperCase()).concat(text.slice(1)):'';}function lowerFirst(text){return text?"".concat(text.charAt(0).toLowerCase()).concat(text.slice(1)):'';}function startCase(text){// prettier-ignore
return text?text.toLowerCase().split(' ').map(function(s){return"".concat(s.charAt(0).toUpperCase()).concat(s.substring(1));}).join(' '):'';}function range(start,end){if(end){return Array.from({length:end-start+1},function(_,i){return start+i;});}return Array.from({length:start},function(_,i){return i;});}/**
 * Get timestamp with query string
 *
 * @param {string} key
 * @returns {string}
 */function getTimestamp(key){return"?v=".concat(_Storage["default"].get(key));}/**
 * Check a number exists between two numbers
 *
 * @param {number} min
 * @param {number} testVal
 * @param {number} max
 * @returns {boolean}
 */function inRange(min,testVal,max){return!!(testVal>=min&&testVal<=max);}/**
 * Scroll to top on selector
 *
 * @param {string} selector
 * @returns {void}
 */function customScrollTo(selector){if(selector){var ps=document.querySelector(selector);if(ps){ps.scrollIntoView(true);}}}/**
 * Convert metric to imperial
 *
 * @returns {number|Object}
 */function convertToImperial(type,value){switch(type){case'weight':return parseFloat(value)*2.20462;case'height':{var newHeight=parseFloat(value)*0.3937;return{feet:Math.trunc(newHeight/12),inches:round(newHeight%12,0)};}default:return null;}}/**
 * Disable background focus when a modal comes out
 */function disableFocusAfterModal(){var logoElement=document.querySelectorAll('.brand');logoElement.forEach(function(elem){elem.setAttribute('tabindex','-1');elem.setAttribute('disabled','disabled');});var linkElement=document.querySelectorAll('#root a');linkElement.forEach(function(elem){elem.setAttribute('tabindex','-1');elem.setAttribute('disabled','disabled');});var buttonElement=document.querySelectorAll('#root button:not(.progress-icon)');buttonElement.forEach(function(elem){elem.setAttribute('tabindex','-1');elem.setAttribute('disabled','disabled');});var divElement=document.querySelectorAll('div[tabindex="0"]');divElement.forEach(function(elem){elem.setAttribute('tabindex','-1');elem.setAttribute('disabled','disabled');});var inputElement=document.querySelectorAll('#root input');inputElement.forEach(function(elem){elem.setAttribute('tabindex','-1');elem.setAttribute('disabled','disabled');});var selectElement=document.querySelectorAll('#root select');selectElement.forEach(function(elem){elem.setAttribute('tabindex','-1');elem.setAttribute('disabled','disabled');});}/**
 * Enable background focus when a modal comes out
 */function enableFocusAfterModal(){var targetElement=document.querySelectorAll('[disabled="disabled"]:not(.progress-icon)');targetElement.forEach(function(elem){elem.setAttribute('tabindex','0');elem.removeAttribute('disabled');});var simulationElement=document.querySelectorAll('.simulation-content button:not(.progress-icon)');simulationElement.forEach(function(elem){elem.setAttribute('tabindex','0');});var navLink=document.querySelectorAll('.nav-link');navLink.forEach(function(elem,key){elem.setAttribute('tabindex',"".concat(parseInt(key,10)+1));});var logoElement=document.querySelectorAll('.brand');logoElement.forEach(function(elem){elem.setAttribute('tabindex','1');});var activeTab=document.querySelector('.nav-item a.active');var tabIndexValue='';if(activeTab&&activeTab.getAttribute&&activeTab.getAttribute('data-rb-event-key')==='Tests'){tabIndexValue='3';}else{tabIndexValue='-1';}var linkElement=document.querySelectorAll('#Tests a');linkElement.forEach(function(elem){elem.setAttribute('tabindex',tabIndexValue);});var buttonElement=document.querySelectorAll('#Tests button');buttonElement.forEach(function(elem){elem.setAttribute('tabindex',tabIndexValue);});var getFocuseBack=document.querySelectorAll("[aria-label=\"".concat(_Storage["default"].get('ref'),"\"]"));if(typeof getFocuseBack[0]!=='undefined'){_Storage["default"].remove('ref');getFocuseBack[0].focus();}var waitingroomImgElement=document.querySelectorAll('.case-form .case-avatar');waitingroomImgElement.forEach(function(elem){elem.setAttribute('tabindex','-1');});}/**
 * Check visibility of guidance
 *
 * @param {string} screen
 * @param {number} guidanceMode
 * @returns {boolean}
 * Individual case info
 * 0 : Show Everywhere => Guidance appearing every where
 * 1 : Never Show => Guidance not appearing in any place
 * 2 : Show at FCC and Case Review => Guidance appearing only in FCC and Case Review in rest places its not visible
 * 3 : Show at Case Review => Guidance appearing in only in Case Review in rest places its not visible
 */function isGuidanceVisible(screen,guidanceMode){switch(guidanceMode){case 0:return true;case 1:return false;case 2:return screen==='Fcc'||screen==='Debrief';case 3:return screen==='Debrief';default:return false;}}/**
 * fix exclusively for iOS *
 */// @todo: Review this effect after release
function fixForiOS(){// The following lines are a fix exclusively for iOS, since it doesn't
// display the main container accordingly
// Reference: https://stackoverflow.com/questions/28927410/fixed-positioned-div-hidden-on-safari
var mobileOs=new _mobileDetect["default"](window.navigator.userAgent).os();var isIPad=new _mobileDetect["default"](window.navigator.userAgent).tablet();var mainElm=document.querySelector('.main-container');if(mainElm&&(mobileOs==='iOS'&&isIPad!=='iPad'||window.matchMedia('(orientation: portrait)').matches&&isIPad==='iPad')){setTimeout(function(){mainElm.style.display='block';},500);}if(mainElm){window.addEventListener('orientationchange',function(){mainElm.style.display='flex';});}}/**
 * Handling performance, while doing the window resize
 *
 * @param {Function} func
 * @param {string} limit
 * @returns {void}
 */function throttle(func,limit){var lastFunc;var lastRan;return function(){var context=this;var args=arguments;if(!lastRan){func.apply(context,args);lastRan=Date.now();}else{clearTimeout(lastFunc);lastFunc=setTimeout(function(){if(Date.now()-lastRan>=limit){func.apply(context,args);lastRan=Date.now();}},limit-(Date.now()-lastRan));}};}/**
 * Getting userid
 *
 * @param {Object} user
 * @returns {number|string}
 */function getUserId(user){var userId=parseInt(user.s27Gd,10);var uid=Number.isNaN(userId)?user.iUserId:userId;return uid;}function displayOneTrustFooterLink(option){var flag=false;switch(option){case'manage-preference':flag='userInEU'in window;break;case'do-not-sell':flag='userInEU'in window&&window.userInEU===false;break;default:flag=false;break;}return flag;}